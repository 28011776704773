import React from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

import {
  PATHS,
  ROLE_MARKETING,
  ROLE_MAINTENANCE,
} from 'constants';
import { getUTCTime, rootname } from 'utils';

import UserControl from './UserControl';
import skyPulseLogo from "assets/logos/SkyPulse.png";
import dalLogo from "assets/logos/DAL.png";
import IfCan from 'containers/IfCan';

import {
  Row,
  Col
} from 'react-bootstrap';
import styles from './styles.module.css';

const Header = (props) => {
  const {
    currentTime,
    location,
    push,
    rConfiguration,
    rInitialized,
    rUser,
  } = props;
  const lrusAndFaultsEnabled = rConfiguration.ff_lrus_and_faults;

  const menuElems = [
    {
      id: 'Global-Header-DashboardButton',
      label: 'Dashboard',
      path: PATHS.dashboard,
      width: 75,
    },
    {
      id: 'Global-Header-ShipButton',
      label: 'Ship',
      path: PATHS.ship,
      width: 40,
      forbiddenRoles: [ROLE_MARKETING.value.name],
    },
    {
      id: 'Global-Header-FlightsButton',
      label: 'Flights',
      path: PATHS.flights,
      width: 55,
    },
  ];

  if (lrusAndFaultsEnabled) {
    menuElems.push(
      {
        id: 'Global-Header-LrusFaultsButton',
        label: 'LRUs & Faults',
        path: PATHS.lrus,
        width: 105,
        forbiddenRoles: [ROLE_MARKETING.value.name],
      }
    )
  }

  menuElems.push(
    {
      id: 'Global-Header-ReportsButton',
      label: 'Reports',
      path: PATHS.reports,
      width: 55,
      forbiddenRoles: [ROLE_MARKETING.value.name],
    }
  )

  if (rUser?.roles.includes(ROLE_MAINTENANCE.id)) {
    menuElems.push(
      {
        id: 'Global-Header-MaintenanceButton',
        label: 'Maintenance',
        path: PATHS.maintenance,
        width: 75,
        forbiddenRoles: [ROLE_MARKETING.value.name],
      },
    );
  }

  return (
    <Row className={styles.container} data-cy-id="Global-Header" noGutters>
      <Col md="auto" className={styles.logo}>
        <img alt="" src={skyPulseLogo} />
      </Col>
      {
        !rInitialized
          ? null
          : <>
            {location ?
              menuElems.map((menuElem, index) => {
                const { id, label, path, width, forbiddenRoles } = menuElem;
                const isActive = (rootname(path) === rootname(location.pathname));
                const className = `${styles.menuItem} ${isActive ? styles.active : ''}`;
                return (
                  <IfCan forbiddenRoles={forbiddenRoles} key={index}>
                    <Col
                      className={className}
                      data-cy-id={id}
                      md="auto"
                      key={index}
                      style={{ width: `${width}px` }}
                      onClick={() => !isActive ? push(path) : false}
                      >
                      {label}
                    </Col>
                  </IfCan>
                );
              }) :
              null
            }
            <Col />
            <Col md="auto" className={styles.orgLogo}>
              <img alt="" src={dalLogo} />
            </Col>
            <Col md="auto" className={styles.time}>
              {getUTCTime(currentTime)} UTC
            </Col>
              {rUser
              ? (
                <Col md="auto">
                  <UserControl user={rUser} />
                </Col>
              )
              : null
              }
          </>
      }
    </Row>
  );
};

Header.propTypes = {
};

const state = (state) => {
  return ({
    currentTime: state.currentTime,
    location: state.router.location,
    rConfiguration: state.configuration,
    rInitialized: state.initialized,
    rUser: state.user,
  });
};

const actions = {
  push
};

export default connect(state, actions)(Header);

import {
  INITIALIZE_APP,
  periodicConfigurationFetch,
  stopPeriodicConfigurationFetch,
  updateConfiguration,
  UNINITIALIZE_APP,
  getUser,
} from 'actions';
import store from 'store';
import { LRUFactory } from 'classes';
import { LRU_TYPES } from 'constants';

export const FETCH_KIOSK_LRUS = 'FETCH_KIOSK_LRUS';
export const FETCH_KIOSK_LRU_DETAIL = 'FETCH_KIOSK_LRU_DETAIL';

const fiveMinInterval = (5 * 60 * 1000);

export const initializeKioskApp = (user) => {
  const { initialized } = store.getState();
  if (!initialized) {
    return async (dispatch) => {
      await dispatch(updateConfiguration());
      await dispatch(getUser(user));
      dispatch({
        type: INITIALIZE_APP,
      });

      setTimeout(() => dispatch(periodicConfigurationFetch()), fiveMinInterval);
    }
  } else {
    return async () => { };
  }
}

export const uninitializeKioskApp = () => {
  return async (dispatch) => {
    dispatch({
      type: UNINITIALIZE_APP,
    });

    dispatch(stopPeriodicConfigurationFetch());
  }
}

export const getTop5LRUTypeCollection = () => {
  const defaultOpts = {
    'page': 1,
    'pageSize': 5,
    'sortField': 'st.fa',
    'sortDirection': 'descending',
  }

  const lruTypeMap = {
    'ITU': [15, 16, 17],
    'MCU': [21, 22],
    'OVD': [5],
    'PCU': [12, 13],
    'Server': [1, 11],
    'SSDCL': [7, 10],
    'WAP': [18, 19, 20],
  };

  const lruData = {};
  return async (dispatch) => {

    const promises = [];

    Object.entries(lruTypeMap).forEach(([key, value]) => {
      value.forEach((ltid) => {
        promises.push(LRUFactory.getLRUsByType(LRU_TYPES[ltid], defaultOpts))
      })
    })

    await Promise.all(promises).then((lrus) => {
      lrus.forEach((lru) => {
        Object.entries(lruTypeMap).map(([key, value]) => {
          if (value.find((lt) => lt === parseInt(lru[0].lru_type.id, 10))) {
            const data = !lruData[key] ? lru
              : lruData[key].concat(lru)
                .sort((a, b) => b.stats.fault_count_seven_day_average - a.stats.fault_count_seven_day_average)
                .slice(0, 5);
            lruData[key] = data;
            return true;
          }
          return false;
        })
      })
    });

    dispatch({
      type: FETCH_KIOSK_LRUS,
      top5LRUTypeCollection: Object.keys(lruData).sort().reduce(function (dt, key) {
        dt[key] = lruData[key];
        return dt;
      }, {}),
    });
  }
}

export const getTop5LRUDetail = (lruType) => {
  const { top5LRUTypeCollection } = store.getState()
  const ids = top5LRUTypeCollection[lruType].map((lru) => lru.id);

  return async (dispatch) => {

    const promises = [];
    ids.forEach(async (id) => {
      promises.push(LRUFactory.getLruById(id));
    });

    const lruData = await Promise.all(promises);

    dispatch({
      type: FETCH_KIOSK_LRU_DETAIL,
      top5LRUDetail: lruData,
    });
  }
}

import { UserFactory } from 'classes';
export const UPDATE_USER = 'UPDATE_USER';
export const GET_USER = 'GET_USER';

export function getUser(user) {
  return async (dispatch) => {
    if (!user) {
      user = await UserFactory.getUserById();
    }
    dispatch({
      type: GET_USER,
      user,
    });
  }
}

export function updateUser(user) {
  return async (dispatch) => {
    await user.save().then((savedUser) => {
      dispatch({
        type: UPDATE_USER,
        user: savedUser,
      });
    });
  }
}

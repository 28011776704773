import React from 'react';
import PropTypes from 'prop-types';

import { User } from 'models';
import Overlay from 'containers/Overlay';
import {
  Button,
  Col,
  Row
} from 'react-bootstrap';
import {
  getUTCDateTime,
} from 'utils';

import styles from './styles.module.css';

const NotificationsOverlay = (props) => {
  const {
    user,
    isOpen,
    onDone,
  } = props;

  const Footer = () => {
    return (
      <Row className={styles.footer} noGutters>
        <Col lg="auto">
          <Button
            className="button_call"
            data-cy-id={`edit-alerts-Overlay-DoneButton`}
            onClick={() => onDone()}
          >
            Done
          </Button>
        </Col>
      </Row>
    )
  }

  if (!user) {
    return null;
  }

  return (
    <Overlay
      data-cy-id="edit-notifications-Overlay"
      contentClassName={styles.overlayRoot}
      headerClassName="w-100"
      bodyClassName={styles.bodyClassName}
      title={`Notifications: ${user.first_name} ${user.last_name}`}
      isOpen={isOpen}
      footer={<Footer />}
      showClose={false}
    >
      <Row className={styles.root} noGutters>
        <Col className={styles.tableContainer}>
          <Row className={styles.header}>
            <Col> Notifications from the previous 24 hours </Col>
          </Row>
          <Row className={styles.notificationContainer}>
            <Col>
              {
                user.notifications.map((notification) => {
                  const {notification_type: notificationType, aircraft_tail: aircraftTail, extra, flight_number: flightNumber, id} = notification;
                  const { value: { name, description } } = notificationType;
                  const { timestamp, aircraft_type: aircraftType } = extra;
                  return (
                    <Row className={styles.notificationBlock} key={id}>
                      <div> {(new Date(timestamp * 1000)).toISOString()} </div>
                      <div>The following alert has been issued by SkyPulse.</div>
                      <div>{flightNumber ? `Flight ${flightNumber}` : ''}{aircraftTail ? `, Ship ${aircraftTail}` : ''}{aircraftType ? `, ${aircraftType}` : ''}</div>
                      <ul>
                        <li>Alert: {name}</li>
                        <li>Detail: {description}</li>
                      </ul>
                      <p>Additional Details</p>
                      <ul>
                        {Object.entries(extra).filter(([key, _]) => {
                          return key !== 'timestamp' && key !== 'aircraft_type';
                        }).map(([key, value]) => {
                          let formattedValue = value;
                          if (key === 'flight_opened') formattedValue = getUTCDateTime(value);
                          return (
                            <li key={key}>
                              {key.replaceAll('_', ' ').replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase())}:  {formattedValue}
                            </li>
                          )
                        })}
                      </ul>
                    </Row>
                  )
                })
              }
            </Col>
          </Row>
        </Col>
      </Row>
    </Overlay>
  );
}

NotificationsOverlay.propTypes = {
  user: PropTypes.instanceOf(User),
  isOpen: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
};

export default NotificationsOverlay;

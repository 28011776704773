import {
  UPDATE_USER,
  GET_USER,
} from 'actions';

export function user(state = {}, action) {
  switch (action.type) {
    case UPDATE_USER:
    case GET_USER:
      return action.user;
    default:
      return state;
  }
}

import React, { useState } from 'react';
import { getUTCShortDate } from 'utils';

import {
  Row,
  Col,
  Button
} from 'react-bootstrap';
import { AIRCRAFT_TYPE_OPTIONS_W_DEFAULT } from 'constants';
import DatePicker from 'components/inputs/DatePicker';
import Select from 'components/inputs/Select';
import ReportSummaryOverlay from 'components/overlays/ReportSummaryOverlay';
import { ReportFactory } from 'classes';

import styles from './styles.module.css';

const idPrefix = 'AvailabilityReport';
const Availability = () => {
  const [fleetOption, setFleetOption] = useState('');
  const [reportDate, setReportDate] = useState(0);
  const [reportOverlayOpen, setReportOverlayOpen] = useState(false);
  const [messageOverlayOpen, setMessageOverlayOpen] = useState(false);

  const isReportDisabled = reportDate === 0 || !fleetOption;

  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    <>
      <Row className={styles.root} noGutters>
        <Col>
          <Row className={styles.title} noGutters>
            <Col className="title_1"> Compact faults report </Col>
            <Col md="auto" className={styles.note}>
              Note: This report is intended for use as source for an external data project.  Data is available for previous 30 days.
            </Col>
          </Row>
          <Row className={styles.filterRow} data-cy-id="Availability-Report-Filters" noGutters>
            <Col>
              <Row className={styles.filterContainer}>
                <Col md="auto" className="mr-5">
                  <Select
                    idPrefix={`${idPrefix}-Fleet`}
                    data-cy-id={`${idPrefix}-FiltersPanel-FleetSelect`}
                    label="Fleet: "
                    options={AIRCRAFT_TYPE_OPTIONS_W_DEFAULT}
                    value={fleetOption.value}
                    onChange={(e) => {
                      const { target: { value } } = e;
                      setFleetOption(value);
                    }}
                  />
                </Col>
                <Col md="auto">
                  <DatePicker
                    data-cy-id={`${idPrefix}-FilterPanel-DateDatePicker`}
                    idPrefix={idPrefix}
                    value={reportDate}
                    label="Date:"
                    minPreviousDays={30}
                    maxDate={yesterday}
                    onChange={(date) => setReportDate(parseInt(date, 10))}
                  />
                </Col>
              </Row>
            </Col>
            <Col md="auto">
              <Button
                className="button_foc"
                data-cy-id="Availability-Report-CreateReportButton"
                disabled={isReportDisabled}
                onClick={() => setReportOverlayOpen(true)}
              >
                Create report
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <ReportSummaryOverlay
        title="Compact faults report"
        data={<>
          <Row>
            <Col> Fleet </Col>
            <Col>
              {fleetOption === '' ? '' : AIRCRAFT_TYPE_OPTIONS_W_DEFAULT[fleetOption].name}
            </Col>
          </Row>
          <Row>
            <Col> Date: </Col>
            <Col> {getUTCShortDate(reportDate)} </Col>
          </Row>
        </>}
        isOpen={reportOverlayOpen}
        loading={messageOverlayOpen}
        onCancel={() => setReportOverlayOpen(false)}
        onDone={async () => {
          setMessageOverlayOpen(true);
          await ReportFactory.getAvailabilityReport(fleetOption, reportDate);
          setMessageOverlayOpen(false);
          setReportOverlayOpen(false);
        }}
      />
    </>
  );
}

export default Availability;

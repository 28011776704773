import {
  INITIALIZE_APP,
  periodicConfigurationFetch,
  startPeriodicDataFetch,
  stopPeriodicConfigurationFetch,
  stopPeriodicDataFetch,
  UNINITIALIZE_APP,
  updateActiveAircraft,
  updateActiveFlight,
  updateAircraftList,
  updateConfiguration,
  updateCurrentTime,
  updateFlightList,
  getUser,
} from 'actions';
import config from 'config';
import store from 'store';

const fiveMinInterval = (5 * 60 * 1000);

export const initializeApp = (user) => {
  const { initialized, aircraftList } = store.getState();

  if (!initialized) {
    return async (dispatch) => {
      await dispatch(updateConfiguration());
      const { configuration, flightList: currentFlightList } = store.getState();
      let fetchData = !(configuration.maintenance_mode);
      if (aircraftList) {
        // Get the latest modified date from aircraft list
        const latestmodifiedDt = Math.max(...aircraftList.map(aircraft => new Date(aircraft.modified)));

        // Calculate 5 min old date
        const todayDate = new Date();
        const fiveMinAgo = todayDate.getTime() - fiveMinInterval;

        // If latest modified date is older than 5 mins than fetch the data
        fetchData = latestmodifiedDt < fiveMinAgo;
      }
      const promises = [];
      const initailFlightAPIPageSize = 1000;
      const dt1DayAgo = new Date().getTime() - (24 * 60 * 60 * 1000);

      promises.push(dispatch(getUser(user)));
      promises.push(dispatch(updateCurrentTime()));
      if (fetchData) {
        promises.push(dispatch(updateAircraftList()));
        promises.push(dispatch(updateFlightList(dt1DayAgo, initailFlightAPIPageSize)));
        promises.push(dispatch(updateActiveAircraft()));
        promises.push(dispatch(updateActiveFlight()));
      }
      await Promise.all(promises);
      dispatch({
        type: INITIALIZE_APP,
      });
      setTimeout(() => dispatch(periodicConfigurationFetch()), fiveMinInterval);
      setTimeout(() => dispatch(startPeriodicDataFetch()), config.app.refreshInterval);

      // Pass 30 days ago date only if there is no flight in the redux.
      const dt30DaysAgo = new Date().getTime() - (30 * 24 * 60 * 60 * 1000);
      dispatch(updateFlightList(currentFlightList?.length > 0 ? null : dt30DaysAgo));
    }
  } else {
    return async () => { };
  }
}

export const unititializeApp = () => {
  return async (dispatch) => {
    dispatch({
      type: UNINITIALIZE_APP,
    });

    dispatch(stopPeriodicConfigurationFetch());
    dispatch(stopPeriodicDataFetch());
  }
}
